import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import React from 'react';
import TagManager from 'react-gtm-module';

import { LocationSlider, TipsSlider } from 'components/shared';
import { PlainLayout } from 'Layout';
import { NextSeo } from 'seo';
import {
	getLocations,
	locationKeys,
	useGetLocationsQuery
} from 'services/shop/location';
import {
	getPromoBannerData,
	useGetPromoBannerData
} from 'services/wordpress/data';

export default function Custom404() {
	const { data: cities } = useGetLocationsQuery();

	const { data: promoBanner } = useGetPromoBannerData();

	React.useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'GTMError',
				eventCategory: '404'
			}
		});
	}, []);

	return (
		<PlainLayout>
			<NextSeo title={`404 -Litto`} />

			<div className="mb-32 mt-36 flex flex-col items-center">
				<p className="mb-2 text-3xl font-bold leading-8">
					Oh, well. Nothing to see here.
				</p>
				<p className="max-w-2xl text-center text-md leading-6">
					Move along, explorer. The page you were looking for does not appear to
					exist. It’s possible that the URL was mistyped, the page may have been
					removed or there’s some other kind of technical error. In any case,
					you should leave this page.
				</p>
			</div>
			{promoBanner && <TipsSlider tipsArray={promoBanner?.acf?.promo_banner} />}
			<div className="my-20">
				<h2 className="container mb-5 text-2xl font-bold">Explore Dalmatia</h2>
				<LocationSlider
					{...(cities ? { cities: cities['hydra:member'] } : null)}
				/>
			</div>
		</PlainLayout>
	);
}

export const getStaticProps: GetStaticProps = async () => {
	const queryClient = new QueryClient();

	const [dataPromoBanner, locations] = await Promise.all([
		getPromoBannerData(),
		getLocations()
	]);

	await Promise.all([
		queryClient.prefetchQuery(['promoBannerData'], () => {
			return dataPromoBanner;
		}),
		queryClient.prefetchQuery(locationKeys.list({}), () => {
			return locations;
		})
	]);

	return {
		props: {
			dehydratedState: dehydrate(queryClient)
		}
	};
};
